import { useCallback, useEffect, useState } from "react";
import { IDevice } from "../../../../modules/device/device.interface";
import { CommonStyles } from "../../../ui";
import useFilterDate from "../../../../hooks/useFilterDate";
import { LMMN, filterDateChart } from "../../../../constants/Index";
import moment from "moment";
import { IDataChart, IDataTime, TOption } from "../../../../interface";
import CommonIcons from "../../../../assets/icons";
import { DatePicker } from "antd";
import {
  getDataFilterDate,
  getDataFilterDateFullTime,
  valueIncreaseByFilterDate,
} from "../../../../helpers/filterDate";
import {
  functionRole,
  getTimeStampHour,
  helpCalculateForDemoMonth1112,
  parseQueryString,
} from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import useToggle from "../../../../hooks/useToggle";
import ModalChoiceTemplate from "../../../ModalChoiceTemplate";
import { useAppSelector } from "../../../../hooks/hooks";
import ModalDetailHistory from "./ModalDetailHistory";
interface IProps {
  detailDevice: IDevice;
}

export default function TableLMMN(props: IProps) {
  //! state
  const user = useAppSelector((state) => state.user.user);
  const { detailDevice } = props;
  const [dataChart, setDataChart] = useState<IDataChart>();
  const [loading, setLoading] = useState(false);
  const { open, shouldRender, toggle } = useToggle();
  const {
    open: openDetailHistory,
    shouldRender: shouldRenderDetailHistory,
    toggle: toggleDetailHistory,
  } = useToggle();
  const [detailHistory, setDetailHistory] = useState<IDataTime>();

  const [sms, setSms] = useState<IDataTime>();
  //! functiom
  const generateOptionsDate = useCallback(() => {
    let options = filterDateChart;

    const currentMonth = moment.utc().month() + 1;
    const item: TOption[] = [
      {
        label: `Tháng ${currentMonth - 1}`,
        value: currentMonth - 2,
      },
      {
        label: `Tháng ${currentMonth - 2}`,
        value: currentMonth - 3,
      },
      {
        label: `Tháng ${currentMonth - 3}`,
        value: currentMonth - 4,
      },
      {
        label: "Tuỳ chỉnh",
        value: "setting",
      },
    ];
    options = [...options, ...item];

    return options;
  }, []);

  const { dateFilter, dateSetting, onChangeFilterDate, onChangeSettingDate } =
    useFilterDate(generateOptionsDate());
  const format = "DD/MM";

  const start = moment.utc(dateFilter.startDate).format(format);
  const end = moment.utc(dateFilter.endDate).format(`${format}/YYYY`);
  const checkSendSMS = functionRole(String(user?.lastName)) === 3;

  const handleDetailHistory = (record: IDataTime) => {
    setDetailHistory(record);
    toggleDetailHistory();
  };
  useEffect(() => {
    const dataDevice = async () => {
      const increaseTime = valueIncreaseByFilterDate(dateFilter);
      setLoading(true);
      // const dataStart = await telemetryService.getTimeSeries(
      //   detailDevice?.id?.entityType,
      //   detailDevice?.id?.id,
      //   parseQueryString({
      //     startTs: dateFilter.startDate,
      //     endTs: dateFilter.endDate,
      //     limit: 1,
      //     useStrictDataTypes: true,
      //     keys: "data",
      //     orderBy: "ASC",
      //   })
      // );
      let startTime = dateFilter.startDate;
      let dataElement: any = [];

      let check = true;
      let count = 1;
      if (dateFilter.type === "second") {
        const detailStation = await telemetryService.getTimeSeries(
          detailDevice?.id?.entityType,
          detailDevice?.id?.id,
          parseQueryString({
            startTs: dateFilter.startDate,
            endTs: dateFilter.endDate,
            limit: 50000,
            useStrictDataTypes: true,
            keys: "data",
            orderBy: "DESC",
          })
        );
        if (detailStation?.data?.data) {
          detailStation?.data?.data.map((item: any, index: any) => {
            const dataOrder = getDataFilterDateFullTime(
              moment(item.ts).valueOf(),
              item.value
            );
            dataElement.push({ ...dataOrder, key: count });
          });
        }
      } else {
        while (startTime <= dateFilter.endDate && check) {
          if (startTime > dateFilter.endDate) {
            startTime = dateFilter.endDate;
            check = false;
          }
          const start = getTimeStampHour(startTime, "start", dateFilter);
          const end = getTimeStampHour(startTime, "end", dateFilter);
          const detailStation = await telemetryService.getTimeSeries(
            detailDevice?.id?.entityType,
            detailDevice?.id?.id,
            parseQueryString({
              startTs: start,
              endTs: end,
              limit: 1,
              useStrictDataTypes: true,
              keys: "data",
              orderBy: "DESC",
            })
          );
          if (detailStation?.data?.data) {
            const dataOrder = getDataFilterDate(
              start,
              detailStation?.data?.data[0]?.value,
              detailStation?.data?.data[0]?.ts
            );
            dataElement.push({ ...dataOrder, key: count });
          }

          startTime += increaseTime;
          count++;
        }
      }
      setDataChart({
        station: detailDevice.name,
        dataChart: dataElement,
      });
      setLoading(false);
    };

    if (detailDevice && dateFilter.value !== "real") {
      dataDevice();
    }
  }, [detailDevice, dateFilter]);
  const columns = [
    // {
    //   title: "STT",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (_: any, record: IDataTime, index: number) => {
    //     return <p>{index + 1}</p>;
    //   },
    // },
    {
      title: "Ngày đo",
      dataIndex: "age",
      key: "date",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record.date}/{record.month}
          </p>
        );
      },
    },
    {
      title: "Giờ đo",
      dataIndex: "address",
      key: "hour",
      render: (_: any, record: IDataTime) => {
        const styleClass =
          dateFilter.type === "hour"
            ? "underline underline-offset-2 cursor-pointer text-main-color"
            : "";
        return (
          <p
            className={styleClass}
            onClick={() => {
              dateFilter.type === "hour" && handleDetailHistory(record);
            }}
          >
            {record?.hour}:{record.minute}
          </p>
        );
      },
    },
    {
      title: "Lượng mưa tích luỹ /h(mm/h)",
      dataIndex: "address",
      key: "rainFall",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record?.data["Hourly rainfall"]?.status
              ? record?.data["Hourly rainfall"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Tổng lượng mưa từ 0h (mm)",
      dataIndex: "totalRainFall",
      key: "totalRainFall",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record?.data["Rainfall on the day"]?.status
              ? record?.data["Rainfall on the day"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Mực nước(cm)",
      dataIndex: "address",
      key: "waterLevel",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record?.data["Water Main Level"]?.status
              ? helpCalculateForDemoMonth1112(
                  detailDevice.id.id,
                  record?.data["Water Main Level"]?.value
                )
              : "-"}
          </p>
        );
      },
    },
    // {
    //   title: "Gửi SMS",
    //   dataIndex: "address",
    //   hidden: checkSendSMS,
    //   key: "sms",
    //   render: (_: any, record: IDataTime) => {
    //     return (
    //       <CommonStyles.TooltipUI title="Gửi SMS">
    //         <CommonStyles.ButtonUI
    //           ghost
    //           className="flex items-center justify-center"
    //           onClick={() => {
    //             toggle();
    //             setSms(record);
    //           }}
    //         >
    //           <CommonIcons.SendOutlined className="-rotate-45 -translate-y-0.5" />
    //         </CommonStyles.ButtonUI>
    //       </CommonStyles.TooltipUI>
    //     );
    //   },
    // },
  ];
  const columnCDRC = [
    // {
    //   title: "STT",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "Ngày đo",
      dataIndex: "age",
      key: "date",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record.date}/{record.month}
          </p>
        );
      },
    },
    {
      title: "Giờ đo",
      dataIndex: "address",
      key: "hour",
      render: (_: any, record: IDataTime) => {
        const styleClass =
          dateFilter.type === "hour"
            ? "underline underline-offset-2 cursor-pointer text-main-color"
            : "";
        return (
          <p
            className={styleClass}
            onClick={() => {
              dateFilter.type === "hour" && handleDetailHistory(record);
            }}
          >
            {record?.hour}:{record.minute}
          </p>
        );
      },
    },
    {
      title: "Căng kế (cm)",
      dataIndex: "CrackMetter",
      key: "CrackMetter",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record.data["CrackMetter"]?.status
              ? record.data["CrackMetter"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Gia tốc thời gian thực (gal)",
      dataIndex: "Vector Gal_Max",
      key: "Vector Gal_Max",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record.data["Vector Gal_Max"]?.status
              ? record.data["Vector Gal_Max"]?.value
              : "-"}
          </p>
        );
      },
    },

    {
      title: "Cường độ thời gian thực (level)",
      dataIndex: "IntensityMax",
      key: "IntensityMax",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record.data["Intensity_Max"]?.status
              ? record.data["Intensity_Max"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Gia tốc gần nhất (gal)",
      dataIndex: "Last Gal_Max",
      key: "Last Gal_Max",
      render: (_: any, record: IDataTime) => {
        return (
          <p>
            {record?.data?.["Last Gal_Max"]?.status
              ? record?.data?.["Last Gal_Max"]?.value
              : record.data["Vector Gal_Max"]?.value}
          </p>
        );
      },
    },
    // {
    //   title: "Gửi SMS",
    //   dataIndex: "address",
    //   key: "sms",
    //   hidden: checkSendSMS,
    //   render: (_: any, record: IDataTime) => {
    //     return (
    //       <CommonStyles.TooltipUI title="Gửi SMS">
    //         <CommonStyles.ButtonUI
    //           ghost
    //           className="flex items-center justify-center"
    //           onClick={() => {
    //             toggle();
    //             setSms(record);
    //           }}
    //         >
    //           <CommonIcons.SendOutlined className="-rotate-45 -translate-y-0.5" />
    //         </CommonStyles.ButtonUI>
    //       </CommonStyles.TooltipUI>
    //     );
    //   },
    // },
  ];
  const renderExtra = () => {
    return (
      <div className="min-w-40">
        <p>{`${start} - ${end}`}</p>
        <CommonStyles.SelectUI
          value={dateFilter.value}
          options={generateOptionsDate()}
          onChange={onChangeFilterDate}
        />
        {dateSetting && (
          <DatePicker.RangePicker
            allowClear={false}
            onChange={onChangeSettingDate}
          />
        )}
      </div>
    );
  };

  //! render
  return (
    <CommonStyles.CardResize
      title="Lịch sử dữ liệu"
      Icon={CommonIcons.Calendar}
      extra={renderExtra()}
    >
      <CommonStyles.TableUI
        columns={detailDevice?.type === LMMN ? columns : columnCDRC ?? []}
        dataSource={dataChart?.dataChart?.reverse()}
        hasPagination
        loading={loading}
        scroll={{
          x: 1000,
        }}
      />
      {shouldRender && (
        <CommonStyles.ModalUI
          title="Chọn mẫu tin nhắn"
          toggle={toggle}
          open={open}
          hiddenAction
        >
          <ModalChoiceTemplate
            detailDevice={detailDevice}
            toggle={toggle}
            dataSms={sms}
          />
        </CommonStyles.ModalUI>
      )}
      {shouldRenderDetailHistory && (
        <CommonStyles.ModalUI
          title="Chi tiết giờ đo"
          toggle={toggleDetailHistory}
          open={openDetailHistory}
          hiddenAction
          width={1100}
        >
          <ModalDetailHistory
            detailHistory={detailHistory}
            detailDevice={detailDevice}
            checkSendSMS={checkSendSMS}
            toggle={toggle}
            setSms={setSms}
          />
        </CommonStyles.ModalUI>
      )}
    </CommonStyles.CardResize>
  );
}
